import { caption } from '@/layout/styles/Typography';
import styled from '@emotion/styled';

const StyledPlan = styled.div`
    border: 1px solid ${({ theme }) => theme.colors.gray2};
    box-shadow: 0px 18px 107px rgba(86, 82, 110, 0.16);
    border-radius: 15px;
    text-align: center;
    max-width: 414px;
    margin: 0 auto;
    overflow: hidden;
    background-color: ${({ theme }) => theme.colors.white};
`;

const Top = styled.div`
    background-color: ${({ theme }) => theme.colors.purple2};
    padding: 16px;

    > p {
        :first-of-type {
            ${caption};
            color: ${({ theme }) => theme.colors.purple3};
            margin: 0;
        }

        :last-of-type {
            margin: 8px 0;
            color: ${({ theme }) => theme.colors.white};
            font-weight: 600;
        }
    }

    > h2 {
        margin: 0;
        font-weight: 600;
        color: ${({ theme }) => theme.colors.white};
    }
`;

const Middle = styled.div`
    padding: 16px 16px 0;

    > p {
        :first-of-type {
            margin: 0 0 4px;
        }

        :last-of-type {
            margin: 0;
            color: ${({ theme }) => theme.colors.gray4};
        }
    }

    > h3 {
        margin: 0 0 24px;
        padding-bottom: 4px;
        border-bottom: 1px solid ${({ theme }) => theme.colors.gray2};
        color: ${({ theme }) => theme.colors.gray5};
    }
`;

const Ul = styled.ul`
    margin: 28px 16px 40px;
    text-align: left;

    > li {
        margin-bottom: 28px;
        text-transform: capitalize;

        :last-of-type {
            margin-bottom: 0;
        }
    }

    @media (min-width: 1024px) {
        margin: 18px 32px 40px;
    }
`;

type SinglePlanProps = {
    firstPerson: string;
    additionalPerson: string;
    includes: string[];
};

export const SinglePlan = ({ firstPerson, additionalPerson, includes }: SinglePlanProps) => {
    return (
        <StyledPlan>
            <Top>
                <p>Annual Savings Plan</p>
                <p>First Person</p>
                <h2>${firstPerson}</h2>
            </Top>
            <Middle>
                <p>Each Additonal Person</p>
                <h3>${additionalPerson}</h3>
                <p>Include:</p>
            </Middle>
            <Ul>
                {includes.map((benefit, i) => (
                    <li key={i}>{benefit}</li>
                ))}
            </Ul>
        </StyledPlan>
    );
};
