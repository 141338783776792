import { Article } from '@/Article';
import { CommonHero } from '@/CommonHero';
import { SinglePlan } from '@/SinglePlan';
import { Seo } from '@/layout/Seo';
import styled from '@emotion/styled';
import { StaticImage } from 'gatsby-plugin-image';
import { Fragment } from 'react';
import dots from '../../../assets/images/icons/dotted-line.svg';

const Plans = styled.div`
    margin-top: 24px;

    @media (min-width: 1024px) {
        position: relative;
        margin-top: 48px;

        ::before,
        ::after {
            content: '';
            position: absolute;
            background: url(${dots}) repeat-x;
            height: 16px;
            width: 515px;
            z-index: -1;
        }
        ::before {
            right: calc((100vw - 846px) / -2);
            top: 0;
        }

        ::after {
            left: calc((100vw - 846px) / -2);
            bottom: 0;
        }
    }
`;

const ourPlans = [
    {
        firstPerson: '350',
        additionalPerson: '300',
        includes: [
            '2 Cleanings',
            '2 Exams',
            '2 Fluoride Treatments',
            '1 FMX and or 1 Set of Bitewing X-rays',
            '1 Emergency exam',
            '10% off Additional Treatments',
            '1 Emergency X-ray',
            '50% Off Custom Bleach Trays',
        ],
    },
];

const OurOffers: Page = () => (
    <Fragment>
        <Seo
            title="Our Offers | Cosmetic Dentistry Services | Auburn"
            description="Discover exciting offers at Nuttall Smiles, home of the best dentist in Auburn and surrounding areas. We provide high quality dentistry solutions."
        />
        <CommonHero img>
            <StaticImage
                src="../../../assets/images/for-patients/our-offers.jpg"
                alt="piggy bank with mask"
                layout="fullWidth"
                loading="eager"
            />
        </CommonHero>
        <Article>
            <h3>No Dental Insurance...No Worry!</h3>
            <p>
                Don’t feel left our if you don’t have insurance. Our savings plan will keep your
                family’s or employee’s dental needs covered.
            </p>
            <h4>Why would you or your business need a Dental Savings Plan?</h4>
            <ul>
                <li>You have no insurance or are about to lose it</li>
                <li>You are paying too much for dental insurance</li>
                <li>You are retired and need dental care</li>
                <li>Your are looking to save money on dental care</li>
            </ul>

            <Plans>
                {ourPlans.map((plan, i) => (
                    <SinglePlan key={i} {...plan} />
                ))}
            </Plans>
        </Article>
    </Fragment>
);

export default OurOffers;
